import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import classNames from 'classnames';

import { breakpoints } from '../../constants';
import useMediaQuery from '../../utils/useMediaQuery';

import { CookieStateContext } from '../../containers/BasePage/state';
import ImageBlock from '../ImageBlock';

import styles from './Hero.module.scss';

const Hero = ({ title, tagLine, image, videoUrl }) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    const [videoStarted, setVideoStarted] = useState(false);

    const { state } = useContext(CookieStateContext) || {};
    const cookieConsent = state?.categories?.includes('targeting');

    if (!image) {
        return null;
    }

    useEffect(() => {
        if (typeof document !== 'undefined') {
            document
                .querySelectorAll('iframe')
                .forEach((i) => (i.tabIndex = -1));
        }
    }, []);

    const classes = classNames(styles['Hero'], {
        [styles['Hero--Text']]: title,
        [styles['Hero--Video']]: videoUrl && videoStarted && !isMobile,
    });

    const videoClasses = classNames(styles['Hero__Video'], {
        [styles['Hero__Video--Playing']]: videoStarted,
    });

    const sizes = '(max-width: 750px) 100vw, 1980px';

    return (
        <header className={classes}>
            {videoUrl && !isMobile && cookieConsent !== undefined && (
                <div className={videoClasses}>
                    <Vimeo
                        className={styles['Hero__VideoPlayer']}
                        video={videoUrl}
                        muted={true}
                        controls={false}
                        tabIndex={-1}
                        volume={0}
                        autoplay={true}
                        height={'100%'}
                        width={'100%'}
                        title={''}
                        background={true}
                        onPlay={() => setVideoStarted(true)}
                        onEnd={() => setVideoStarted(false)}
                        dnt={!cookieConsent}
                    />
                </div>
            )}
            {image && (
                <ImageBlock
                    {...image}
                    sizes={sizes}
                    creditsRight={true}
                    shouldLazyLoad={false}
                    showDownload={!videoStarted}
                />
            )}
            {title && (
                <div className={styles['Hero__Content']}>
                    <h1 className={styles['Hero__Title']}>
                        <div className={styles['Hero__TagLine']}>{tagLine}</div>
                        {title}
                    </h1>
                </div>
            )}
        </header>
    );
};

Hero.propTypes = {
    title: PropTypes.string,
    tagLine: PropTypes.string,
    image: PropTypes.object,
    videoUrl: PropTypes.string,
};

Hero.defaultProps = {
    title: '',
    videoUrl: '',
    image: null,
};

export default Hero;
