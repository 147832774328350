import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import SlideInFromTop from '../../transitions/SlideInFromTop';

import { useTranslation } from 'next-i18next';

import styles from './CrisisInformationBar.module.scss';

const CrisisInformationBar = ({ content, href }) => {
    const { t } = useTranslation();

    if (!content) return null;

    return (
        <SlideInFromTop visible={true}>
            <div className={styles['CrisisInformationBar']}>
                <div className={styles['CrisisInformationBar__Container']}>
                    <span className={styles['CrisisInformationBar__Icon']} />
                    <div className={styles['CrisisInformationBar__Content']}>
                        <div
                            className={styles['CrisisInformationBar__Text']}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                        {href && (
                            <Button
                                text={t('crisisinformationbar.readmore')}
                                type={'Plain'}
                                link={href}
                                icon={'/svg/icon-arrow.svg'}
                            />
                        )}
                    </div>
                </div>
            </div>
        </SlideInFromTop>
    );
};

CrisisInformationBar.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
};

CrisisInformationBar.defaultProps = {
    title: '',
    content: '',
};

export default CrisisInformationBar;
