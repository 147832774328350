import React from 'react';
import { basePageWrap } from '../BasePage';
import styles from './HomePage.module.scss';

import Hero from '../../components/Hero';
import StreamField from '../../components/StreamField';
import isEmpty from '../../utils/isEmpty';
import CrisisInformationBar from '../../components/CrisisInformationBar';

const HomePage = ({ hero, preamble, contentBlocks, siteSetting }) => (
    <div className={styles['HomePage']}>
        <Hero {...hero} />
        {siteSetting && siteSetting.crisisInformation && (
            <CrisisInformationBar {...siteSetting.crisisInformation} />
        )}

        <div className={styles['HomePage__PreambleWrap']}>
            <div
                className={styles['HomePage__Preamble']}
                dangerouslySetInnerHTML={{ __html: preamble }}
            />
        </div>

        {!isEmpty(contentBlocks) && <StreamField items={contentBlocks} />}
    </div>
);

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default basePageWrap(HomePage);
